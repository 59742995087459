import React, { useEffect, useState } from "react";
import { Divider } from "@mui/material";
import api from "../API/api";

const Report = () => {
    const [answers, setAnswes] = useState();

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        api.allanswers()
            .then(res => {
                console.log("res", res);
                setAnswes(res.data.data);
            })
            .catch(err => {
                console.log(err.response);
            });
    };

    return (
        <>
            <div className="container">
                {answers &&
                    answers.map(el => (
                        <div className="row border border-success">
                            <div>
                                <h3>
                                    <strong>User - {el.name}</strong>
                                </h3>
                            </div>

                            <div>Email - {el.email}</div>
                            <div>Start Time - {el.start_time}</div>
                            <div>End Time - {el.end_time}</div>
                            {/* <div>join date {el?.created_at}</div> */}
                            <div>
                                Join Date: {el?.created_at}{" "}
                                {(() => {
                                    const joinDate = new Date(el?.created_at);
                                    const currentDate = new Date();
                                    const diffTime = Math.abs(
                                        currentDate - joinDate
                                    ); // Difference in milliseconds
                                    const diffDays = Math.ceil(
                                        diffTime / (1000 * 60 * 60 * 24)
                                    ); // Convert to days

                                    return diffDays <= 7 ? (
                                        <span className="tag new">New</span>
                                    ) : (
                                        <span className="tag old">Old</span>
                                    );
                                })()}
                            </div>
                            <Divider />
                            <br />
                            <br />
                            <br />
                            <div className="text-success">
                                <strong>
                                    <u>Assignment 1.</u>
                                </strong>
                            </div>

                            <div>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: el.ans1
                                    }}
                                />
                            </div>
                            <br />
                            <br />
                            <br />
                            <Divider />
                            <br />
                            <br />
                            <br />

                            <div className="text-success">
                                <strong>
                                    <u>Assignment 2.</u>
                                </strong>
                            </div>

                            <div>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: el.ans2
                                    }}
                                />
                            </div>
                            <br />
                            <br />
                            <br />
                            <Divider />
                            <br />
                            <br />
                            <br />

                            <div className="text-success">
                                <strong>
                                    <u>Assignment 3.</u>
                                </strong>
                            </div>

                            <div>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: el.ans3
                                    }}
                                />
                            </div>
                        </div>
                    ))}
            </div>
            <div>
                <style>
                    {`
                    .tag {
                        padding: 2px 6px;
                        border-radius: 4px;
                        font-size: 12px;
                    }

                    .tag.new {
                        background-color: #4caf50;
                        color: white;
                    }

                    .tag.old {
                        background-color: #f44336;
                        color: white;
                    }

                    .container {
                        margin-top: 20px;
                    }

                    .row {
                        margin-bottom: 20px;
                        padding: 10px;
                    }

                    .text-success {
                        color: #28a745;
                    }

                    .border-success {
                        border-color: #28a745 !important;
                    }
                    `}
                </style>
            </div>
        </>
    );
};

export default Report;
